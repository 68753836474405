












import { defineComponent } from '@vue/composition-api';
import MappingsEditComponent from '@/components/MappingsEditComponent.vue';
import Store from '@/store';
import router from '@/router';

export default defineComponent({
  components: {
    MappingsEditComponent,
  },
  setup() {
    const mappingStepTitle = 'Please make any changes you wish and click save.';
    const mappingStepSecondTitle = 'Please make any changes you wish and click save.';
    const isPublished = (router.app.$route.params.isPublished === 'true');

    const mappingStepHeaders = [
      {
        text: 'Work Order Field',
        align: 'start',
        value: 'workOrderFieldName',
      },
      { text: 'Job Field', value: 'jobFieldName' },
      { text: 'Use Description', value: 'description' },
      { text: 'Actions', value: 'actions', sortable: false },
    ];

    const mappingStepSecondHeaders = [
      {
        text: 'Job Workflow Step',
        align: 'start',
        value: 'jobWorkflowName',
      },
      { text: 'Work Order  Field', value: 'workOrderFieldName' },
      { text: 'Use Description', value: 'description' },
      { text: 'Actions', value: 'actions', sortable: false },
    ];

    const tabs = ['WO to Job', 'Job to WO'];

    Store.commit('templateMapping/setTemplateJobs', []);

    return {
      mappingStepHeaders,
      tabs,
      isPublished,
      mappingStepTitle,
      mappingStepSecondTitle,
      mappingStepSecondHeaders,
    };
  },
});
